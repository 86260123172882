import React from 'react';
import styles from './Premium.module.scss';

const Premium = () => {
  return (
    <div className={styles.premiumContainer}>
      <div className={styles.header}>
        <h1>AI ACADEMIC <span className={styles.proBadge}>PRO</span></h1>
        <p>Next Level Academic Research & Writing tool with Advanced AI</p>
      </div>
      <div className={styles.accessList}>
        <p>Get Access To</p>
        <ul>
          <li>AI Advanced Academic Writing Tool</li>
          <li>Unlimited Access to Academic Writing & Research</li>
          <li>Extensive Research Database</li>
          <li>No Ads</li>
        </ul>
      </div>
      <div className={styles.pricingOptions}>
        <div className={styles.popularBox}>
          <div className={styles.priceHeader}>
            <h2 className={styles.oneTimeOfferBadge}>POPULAR</h2>
          </div>
          <div className={styles.priceContent}>
            <p className={styles.currentPrice}>$8/week</p>
            <p className={styles.oldPrice}>$15.99</p>
            <p className={styles.totalPrice}>$99.99</p>
            <p>3 Days Free Trial</p>
          </div>
        </div>
        <div className={styles.priceBox}>
          <div className={styles.priceHeader}>
            <h2 className={styles.oneTimeOfferBadge}>ONE TIME OFFER</h2>
          </div>
          <div className={styles.priceContent}>
            <p className={styles.currentPriceYearly}>Yearly $8</p>
            <p className={styles.oldPrice}>$15.99</p>
            <p className={styles.totalPrice}>$99.99</p>
            <p className={styles.monthlyPrice}>$12.99/month</p>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <p>3 Days Free Trial then <span className={styles.oldPrice}>$150.25</span> $99.99</p>
        <button className={styles.buyButton}>Buy Premium</button>
        <div className={styles.links}>
          <a href="#">Restore Purchase</a>
          <a href="#">Terms & Conditions</a>
        </div>
      </div>
    </div>
  );
}

export default Premium;