import React from 'react';
import styles from './PrivacyPolicyPage.module.scss';

const PrivacyPolicyPage = () => {
  return (    

    <div className={styles.privacyPolicy}>
      <div className={styles.content}>
        <h2 className={styles.h2}>Privacy Policy</h2>    
        <p className={`${styles.mt2} ${styles.textColor}`}>Privacy Policy for AI Academic Writer</p>
        <p className={`my-1 ${styles.textColor}`}>Effective Date: 13-7-2023</p>
        <div className="mt-4">
          <p>Thank you for using AI Proposal (referred to as "the App"), developed by Speed Apps (referred to as "we", "us", or "our"). This Privacy Policy outlines
            how we collect, use, disclose, and safeguard your personal information when you use our iOS application. By using the App, you consent to the practices
            described in this Privacy Policy.</p>
          <h3 className="mt-6 mb-2 font-semibold">1. Information We Collect</h3>
          <p>We may collect personal information that you provide directly to us when using the App. This includes:</p>
          <ul className="list-disc pl-5">
            <li>Your name, email address, and other contact information when you sign up or contact us for support.</li>
            <li>Story content and other user-generated content you provide within the App.</li>
            <li>Communication and interaction details when you communicate with other users or us through the App.</li>
          </ul>
          <h4 className="mt-4 mb-2 font-semibold">1.2 Usage Information:</h4>
          <p>When you use the App, we may automatically collect certain information about your device and usage patterns, including:</p>
          <ul className="list-disc pl-5">
            <li>Device information (such as model, operating system version, unique device identifiers).</li>
            <li>Log information (such as IP addresses, access dates and times, and referring/exit pages).</li>
            <li>App usage data, including the features you use, the pages you visit, and the actions you take.</li>
          </ul>
          <h3 className="mt-6 mb-2 font-semibold">2. Use of Information</h3>
          <h4 className="mt-4 mb-2 font-semibold">2.1 Provide and Improve the App:</h4>
          <p>We use the collected information to provide, maintain, and improve the functionality and performance of the App. This includes:</p>
        
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
