import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import sidebarLinks from '../../../constants/sidebarLinks';
import aiAcademic from '../../../assets/images/academic.svg';
import crown from '../../../assets/images/crown.svg';
import hamburger from '../../../assets/images/hamburger.png';
import whiteAcademic from '../../../assets/images/white-academic.png';
import closeIcon from '../../../assets/images/close.svg';

import styles from './Sidebar.module.scss';

function Sidebar() {
  const navigate = useNavigate();

  const [tab, setTab] = useState('Home');
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const location = useLocation();

  console.log(location);

  function handleTabClick(path) {
    setTab(path);
    navigate(path);
    setToggleSidebar(false);
  }

  function handleBuyPremiumClick() {
    navigate('/premium');
  }

  function renderSidebarLinks(link, index) {
    let sidebarLinkClassName = styles.sidebarLink;

    if (link.to === location.pathname) {
      sidebarLinkClassName += ` ${styles.active}`;
    }

    return (
      <div className={sidebarLinkClassName} key={index} onClick={() => handleTabClick(link.to)}>
        <link.icon className={styles.linkIcon} />
        <label>{link.label}</label>
      </div>
    );
  }

  function renderSidebarContent() {
    return (
      <>
        {/* <div className='text-center'>
          <button className={styles.buyPremium} onClick={handleBuyPremiumClick}>
            <img src={crown} className={styles.crown} />
            <span>Buy Premium</span>
          </button>
        </div> */}

        <div className={styles.sidebarLinksContainer}>
          {sidebarLinks.map((link, index) => renderSidebarLinks(link, index))}
        </div>
      </>
    );
  }

  function renderDesktopSidebarWrapper() {
    return (
      <div className={styles.desktopSidebarWrapper}>
        <div className={styles.aiAcademic}>
          <img src={aiAcademic} className={styles.aiAcademicLogo} />
          <h2>AI Academic</h2>
        </div>
        {renderSidebarContent()}
      </div>
    );
  }

  function renderMobileHeader() {
    return (
      <div className={styles.mobileHeader}>
        <button className={styles.iconButton} onClick={() => setToggleSidebar(true)}>
          <img src={hamburger} className={styles.hamburgerIcon} />
        </button>

        <div className={styles.rightContent}>
          <button className={styles.iconButton}>
            <img src={crown} />
          </button>
        </div>
      </div> 
    );
  }

  function renderMobileSidebar() {
    if (!toggleSidebar) return;

    return (
      <div className={styles.mobileSidebar}>
        <div className={styles.mobileSidebarHeader}>
          <img src={whiteAcademic} className={styles.academicImage} />
          <h3>AI ACADEMIC</h3>
          <button className={styles.closeButton} onClick={() => setToggleSidebar(false)}>
            <img src={closeIcon} />
          </button>
        </div>
        {renderSidebarContent()}
      </div>
    );
  }

  function renderMobileSidebarWrapper() {
    return (
      <div className={styles.mobileSidebarWrapper}>
        {renderMobileHeader()}
        {renderMobileSidebar()}
      </div>
    );
  }

  return (
    <>
      {renderDesktopSidebarWrapper()}
      {renderMobileSidebarWrapper()}
    </>
  );
}

export default Sidebar;