import { ReactComponent as WriteAcademicIcon } from '../assets/images/writeAcademicIcon.svg';
import { ReactComponent as HistoryIcon } from '../assets/images/historyIcon.svg';

const writeAcademicTabs = [
  {
    icon: WriteAcademicIcon,
    label: 'Write Academic',
    value: 'write',
    to: './'
  },
  {
    icon: HistoryIcon,
    label: 'History',   
    value: 'history',
    to: './History'
  },
];

export default writeAcademicTabs;  