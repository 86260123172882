import React from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivacyPolicyPage from './components/pages/privacyPolicy/PrivacyPolicyPage';
import Home from './components/pages/home/Home';
import Sidebar from './components/generic/sidebar/Sidebar';
import History from './components/pages/history/History';
import ReplyBox from './components/replybox/ReplyBox';
import Premium from './components/pages/premium/Premium'; // Adjust the path as necessary
import styles from './App.module.scss';

const App = () => {
  ReactGA.initialize('G-MJ40YRK98L');

  return (
    <Router>
      <div className={styles.appLayout}>
        <Sidebar />
        <div className={styles.mainBody}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/PrivacyPolicy" element={<PrivacyPolicyPage />} />
            <Route exact path="/History" element={<History />} />
            <Route exact path="/reply-box" element={<ReplyBox />} />
            <Route exact path="/premium" element={<Premium />} /> {/* Add this line */}
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;