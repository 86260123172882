import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../../components/loaderSpinner/Animation - 1717138711835.json';
import styles from './LoadingSpinner.module.scss';

function LoadingSpinner() {
  return (
    <div className={styles.spinner}>
      <Lottie 
        animationData={animationData} 
        loop={true} 
        className={styles.lottie} 
      />
    </div>
  );
}

export default LoadingSpinner;
