import { ReactComponent as HomeIcon } from '../assets/images/home.svg';
// import { ReactComponent as SettingsIcon } from '../assets/images/setting.svg';
import { ReactComponent as PrivacyPolicyIcon } from '../assets/images/description.svg';
// import { ReactComponent as RateUsIcon } from '../assets/images/grade.svg';
import { ReactComponent as HistoryIcon } from '../assets/images/historyIcon.svg'

const sidebarLinks = [
  {
    icon: HomeIcon ,
    label: 'Home',
    to: '/'
  },
  // {
  //   icon: SettingsIcon ,
  //   label: 'Settings',
  //   to: '/settings'
  // },
  // {    
  //   icon:HistoryIcon ,
  //   label: 'History',
  //   to: '/History'
  // },
  {
    icon: PrivacyPolicyIcon ,
    label: 'Privacy Policy',
    to: '/PrivacyPolicy'
  },
  // {
  //   icon: RateUsIcon ,
  //   label: 'Rate Us',
  //   to: '/rate-us'
  // }
];

export default sidebarLinks;