export function checkAndResetLimit() {
  const today = new Date().toLocaleDateString();
  const savedDate = localStorage.getItem('generateDate');

  if (savedDate !== today) {
    localStorage.setItem('generateDate', today);
    localStorage.setItem('generateCount', '0');
  }
}

export function incrementGenerateCount() {
  let generateCount = parseInt(localStorage.getItem('generateCount') || '0', 10);
  generateCount += 1;
  localStorage.setItem('generateCount', generateCount.toString());
}
