import React, { useState, useEffect } from 'react';
import styles from './History.module.scss';
import copyIcon from './Layer 10 (1).png';
import shareIcon from './Group (1).png';
import axios from 'axios';

function History() {
 
  const [history, setHistory] = useState([]);
  const userId = localStorage.getItem('user_id');
  const user_name = localStorage.getItem('user_name');
  useEffect(() => {

    const userData = {
      userId: userId

        };

    
      axios.post('https://backend.conquerapps.com/api/data', userData, {
        headers: {    
         'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'https://conquerapps.com/',
        }
      } )
        .then((response) => {
          console.log(response.data);
          const storedHistory = response.data;
          setHistory(storedHistory);
          
        })
        .catch((error) => {
          console.error('There was an error fetching the data!', error);
        });
   
    
      // setHistory(storedHistory);
    }, []);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard');
  };

  const handleShare = (text) => {
    if (navigator.share) {
      navigator.share({
        title: 'Shared Content',
        text: text,
        url: window.location.href,
      }).catch(console.error);
    } else {
      alert('Share not supported on this browser');
    }
  };

  return (
    <div className={styles.history}>
      {history.length > 0 ? (
        <ul>
          {history.map((item, index) => (
            <li key={index}>
              
              <span>{item.description}</span>
              <div className={styles.buttons}>
                <button onClick={() => handleCopy(item.description)}>
                  <img src={copyIcon} alt="Copy" />
                </button>
                <button onClick={() => handleShare(item.description)}>
                  <img src={shareIcon} alt="Share" />
                </button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No history found.</p>
      )}
    </div>
  );
}

export default History;