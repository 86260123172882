import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './ReplyBox.module.scss';
import LoadingSpinner from '../loaderSpinner/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft ,faShareFromSquare , faCopy} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jwtDecode } from 'jwt-decode';

export default function ReplyBox() {
  const location = useLocation(); 
  const navigate = useNavigate();
  const [loading, setLoading] = useState(location.state.loading || false);
  const [content, setContent] = useState('');
  const [displayedContent, setDisplayedContent] = useState('');
  const [wordIndex, setWordIndex] = useState(0);
  const [user, setUser] = useState(null);
  const user_id = localStorage.getItem('user_id');
  const user_name = localStorage.getItem('user_name');
  const user_email = localStorage.getItem('user_email');
  const generateCount = parseInt(localStorage.getItem('generateCount'));


    useEffect(() => {
      console.log(generateCount);
      // console.log("Location state:", location.state); // Log location state     
        generateReply();
        
    }, []);

  useEffect(() => {
    if (content) {
      const words = content.split(' ');
      if (wordIndex < words.length) {
        const timer = setTimeout(() => {                   
          setDisplayedContent((prev) => prev + ' ' + words[wordIndex]);
          setWordIndex((prev) => prev + 1);
        }, 50); // Adjust the delay as needed 
        return () => clearTimeout(timer);
      }
    }
  }, [content, wordIndex]);

  async function generateReply() {
    try {
      const url = 'https://api.openai.com/v1/chat/completions'; 
      
      const params = {
        "model": "gpt-3.5-turbo",
        "messages": [
          {
            "role": "user",
            "content": "write a academic writing on " + location.state.description
            + " & keep the type " + location.state.type
            + " & keep the citations in " + location.state.citation + " style"
            + " & keep the tone " + location.state.tone
            + " & keep the language " + location.state.language,   
          }
        ],
        "temperature":0.2,
      };
      setLoading(true);
      const response = await axios.post(url, params, {
        headers: {    
          'Content-Type': 'application/json',
          'Authorization': `Bearer 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`, // Replace with your actual API key securely
        }
      });
      
      if (JSON.stringify(response.data)) {
          
         // alert(1);
         //console.log(JSON.stringify(response.data.choices[0].message.content));
         let strObjj = response.data.choices[0].message.content;
         let strObj = strObjj.replace("\n\n", "");
         console.log(strObj.replace(/\s+/g,' ').trim());
         setContent(strObj.replace(/\s+/g,' ').trim());
         localStorage.setItem('content', strObjj);
         let type = location.state.type;
         let description = location.state.description;
         let citation = location.state.citation;
         let tone = location.state.tone;
         let language = location.state.language;
         let content  = strObj.replace(/\s+/g,' ').trim();
         console.log(user_id);
         console.log(user_name);
         
         const userData = {
            userId: user_id,
            des:description,
            type: type,
            citation:citation,
            tone:tone,
            language:language,
            content:content,
            generateCount:generateCount

          };
            
          console.log('Sending data:', userData);
          if(user_id){
              axios.post('https://backend.conquerapps.com/api/insert', userData, {
                headers: {    
                'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': 'https://conquerapps.com/',
                }
              } )
                
                .then((response) => {
                  console.log(response.data.message);
                //fetchData(); // Refresh the data after insertion
                })
                
                .catch((error) => {
                  console.error('There was an error inserting the data!', error.message);
                  console.error(error.response ? error.response.data : 'No response from server');
                });
          }
              
      }
      // You can also handle the response.data as needed for your application
      setLoading(false);
    } catch (error) {
      alert(`Error: ${error.response ? JSON.stringify(error.response.data) : error.message}`);
      console.error(error);
    }
  }

  // copy & share here 

  function handleCopy() {
    navigator.clipboard.writeText(displayedContent).then(() => {
      alert('Content copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  }

  function handleShare() {
    if (navigator.share) {
      navigator.share({
        title: 'Shared Content',
        text: displayedContent,
      }).then(() => {
        console.log('Content shared successfully');
      }).catch((error) => {
        console.error('Error sharing content: ', error);
      });
    } else {
      alert('Share feature is not supported in your browser.');
    }  
  }

  //content regarding changes from here 

  return (
    
    <div className={styles.replyBoxContainer}>
    <ToastContainer className={styles.toastContainer}/>
    {loading ? (
      <h3><LoadingSpinner /></h3>
      ) : (
        <>
          {displayedContent && (  
            <div className={styles.backContainer}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className={styles.backIcon}
                onClick={() => navigate(-1)}
              />
              <span className={styles.backText}>Your Academic</span>
              <div className={styles.buttonContainer}>
                <FontAwesomeIcon icon={faCopy} className={styles.icon} onClick={handleCopy} />
                <FontAwesomeIcon icon={faShareFromSquare} className={styles.icon} onClick={handleShare} />
              </div>
            </div>
          )}
          <div className={styles.showContent}>
            <h3>{displayedContent}</h3>
          </div>
        </>
      )}
    </div>
  )
}
