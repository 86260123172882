import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactGA from "react-ga4";
import styles from './Home.module.scss';
import LoadingSpinner from '../../loaderSpinner/LoadingSpinner';
import writeAcademicTabs from '../../../constants/writeAcademicTabs';
import arrowLongRight from '../../../assets/images/arrowLongRightIcon.png';
import { useNavigate } from 'react-router-dom';
import { checkAndResetLimit, incrementGenerateCount } from '../../../components/pages/utils/contentLimit';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import History from '../history/History';

function Home() {
  const navigate = useNavigate();
  const [loading] = useState(false);
  const [tab, setTab] = useState('write');
  const user_id = localStorage.getItem('user_id');
  const user_name = localStorage.getItem('user_name');

  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "Home",
  });
  const [formState, setFormState] = useState({
    description: '',
    type: 'Analytical',
    citation: 'APA',
    tone: 'No Tone',
    language: 'English',
  });


  useEffect(() => {
    checkAndResetLimit();
  }, []);

  // increase and decrease limitation on content 

  function handleGenerateReply() {
    const generateCount = parseInt(localStorage.getItem('generateCount') || '0', 10);
    if (generateCount >= 5) {
      Swal.fire({
        title: 'Daily Limit Reached',
        text: 'Your daily limit has been completed',
        icon: 'warning',
        confirmButtonText: 'OK'
      });
      return;
    }
    if (!formState.description) {
      Swal.fire({
        title: 'Description Box is Empty!',
        text: 'Please enter a description.',
        icon: 'error',
        confirmButtonText: 'OK',
        customClass: {
          container: 'custom-toast'
        }
      }).then(() => {
        setTimeout(() => navigate('/'), 1000);
      });
      return;
    }

    incrementGenerateCount();
    navigate('reply-box', { state: { ...formState, loading: true } });
  }
  function handleFormControlChange(event) {
    const { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  }
  function handleTabClick(value) {
    setTab(value)
    // navigate(path)
  }

  function renderTab(_tab, index) {
    let tabClassName = styles.tab;
    let tabIconClassName = styles.tabWriteIcon;

    if (_tab.value === tab) {
      tabClassName += ` ${styles.active}`;
    }

    if (_tab.value === 'history') {
      tabIconClassName = styles.tabHistoryIcon;
    }

    return (
      <div
        className={tabClassName}
        key={index}
        onClick={() => {
          setTab(_tab.value);
          handleTabClick(_tab.value);
        }}
      >
        {tab.icon && <_tab.icon className={tabIconClassName} />}
        <label>{_tab.label}</label>
      </div>
    );
  }

  function renderTabContent() {

    if (tab === 'history') {
      return <History />;
    }

    return (
      <>
        <div className={styles.form}>
          <div className='form-group'>
            <label className='form-label'>Topic/Description</label>
            <textarea className={styles.dess}
              name='description'
              placeholder='Enter Topic/Description'
              value={formState.description}
              onChange={handleFormControlChange}
            />
          </div>

          <div className='row'>
            {/* Analytical */}

            <div className={`col-md-6 ${styles.customColClass}`}>
              <div className={`${styles.formGroup} ${styles.type}`}>
                <label className='form-label'>SELECT TYPE</label>
                <select name='type' value={formState.type} onChange={handleFormControlChange}
                  className={`${styles.customSelect01} ${styles.customSelectClass}`} style={styles.customSelectStyle}>
                  <option value="No Type"> No Type</option>
                  <option value="Analytical">Analytical</option>
                  <option value="Technical" >Technical</option>
                  <option value="Argumentative">Argumentative </option>
                  <option value="Expository">Expository</option>
                  <option value="Narrative">Narrative</option>
                  <option value="Persuasive">Persuasive</option>
                  <option value="Comparing/Contrast">Comparing/Contrast</option>
                  <option value="Classic">Classic</option>
                  <option value="Critique">Critique</option>
                  <option value="Memoir">Memoir</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>

            {/* Citation */}
            <div className='col-md-6'>
              <div className={`${styles.formGroup} ${styles.citation}`}>
                <label className='form-label'>SELECT CITATION</label>
                <select name='citation' value={formState.citation} onChange={handleFormControlChange} className={styles.customSelect02}>
                  <option value="No Citation"> No Citation</option>
                  <option value="APA">APA</option>
                  <option value="MLA">MLA</option>
                  <option value="CMS OR Chicago Style">CMS OR Chicago Style</option>
                  <option value="IEEE">IEEE</option>
                  <option value="Harvard style">Harvard style</option>
                  <option value="AMA">AMA</option>
                  <option value="Vancouver">Vancouver</option>
                  <option value="Turbian">Turbian</option>
                  <option value="No Style">No Style</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
          </div>

          <div className='row'>
            {/* Tone */}
            <div className='col-md-6'>
              <div className={`${styles.formGroup} ${styles.Tone}`}>
                <label className='form-label'>SELECT TONE</label>
                <select name='tone' value={formState.tone} onChange={handleFormControlChange} className={styles.customSelect03}>
                  <option value="No Tone">No Tone</option>
                  <option value="Professional">Professional</option>
                  <option value="Formal">Formal</option>
                  <option value="InFormal">InFormal</option>
                  <option value="Friendly">Friendly</option>
                  <option value="Funny">Funny</option>
                  <option value="Convincing">Convincing</option>
                  <option value="Luxury">Luxury</option>
                  <option value="Relaxed">Relaxed</option>
                  <option value="Professional">Professional</option>
                  <option value="Witty">Witty</option>
                  <option value="Adventurous">Adventurous</option>
                  <option value="Persuasive">Persuasive</option>
                  <option value="Bold">Bold</option>
                  <option value="Empathetic">Empathetic</option>
                </select>
              </div>
            </div>

            {/* Language */}
            <div className='col-md-6'>
              <div className={`${styles.formGroup} ${styles.Language}`}>
                <label className='form-label'>SELECT LANGUAGE</label>
                <select name='language' value={formState.language} onChange={handleFormControlChange} className={styles.customSelect04}>
                  <option value="No Language">No Language</option>
                  <option value="English" >English</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Arabic">Arabic</option>
                  <option value="Bulgarian">Bulgarian</option>
                  <option value="Chinese">Chinese</option>
                  <option value="French">French</option>
                  <option value="German">German</option>
                  <option value="Hebrew (RTL)">Hebrew (RTL)</option>
                  <option value="Indonesian">Indonesian</option>
                  <option value="Italian">Italian</option>
                  <option value="Japanese">Japanese</option>
                  <option value="Portuguese">Portuguese</option>
                  <option value="Punjabi">Punjabi</option>
                  <option value="Russian">Russian</option>
                  <option value="Spanish">Spanish</option>
                  <option value="Swahili">Swahili</option>
                  <option value="Tagalog">Tagalog</option>
                  <option value="Urdu">Urdu</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        {!user_id && (
          <p className={styles.notLoggedInText}>Not logged in; history unavailable. Generation is possible.</p>
        )}
        <div className='text-center'>
          <button className={styles.generateReply} onClick={handleGenerateReply} disabled={loading}>
            {loading ? <LoadingSpinner /> : (
              <>
                <span>Generate Reply</span>
                <img src={arrowLongRight} className={styles.arrowLongRight} alt="Arrow" />
              </>
            )}
          </button>
        </div>

        {loading && (
          <div className={styles.loadingOverlay}>
            <LoadingSpinner />
          </div>
        )}
      </>
    );
  }

  const handleLogout = () => {
    googleLogout();
    localStorage.removeItem("user_name");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_email");
    window.location.reload();
  };

  return (
    <div className={styles.home}>

      <ToastContainer
        className={styles.toastContainer}
        toastClassName={styles.toast}
        position="top-center"
        autoClose={5000} hideProgressBar={true} closeOnClick pauseOnHover draggable newestOnTop
      />

      <div className={styles.header}>
        <h1>AI Academic Writer</h1>
        { user_id ? (
          <div>
            <h2>Welcome, {user_name}</h2>
            <button onClick={handleLogout}>Logout</button>
          </div>
        ) : (
          <GoogleOAuthProvider clientId="620425336688-257ioc5eu8toeo52n2cgqgkp5o96rr3t.apps.googleusercontent.com">

            <GoogleLogin
              onSuccess={credentialResponse => {
                console.log(credentialResponse);
                const decoded = jwtDecode(credentialResponse.credential);
                console.log('Decoded Token:', decoded);
                const userId = decoded.sub;
                const userName = decoded.name;
                const userEmail = decoded.email;
                const picture = decoded.picture;
                console.log('User ID:', userId);
                console.log('User Name:', userName);
                console.log('User Email:', userEmail);
                console.log('User Picture:', picture);

                // Store the userid in local storage
                localStorage.setItem('user_id', userId);
                localStorage.setItem('user_name', userName);
                localStorage.setItem('user_email', userEmail);
                const userData = {
                  userId: userId
            
                    };
                  const userDataa = {
                      userId: userId,
                      userName: userName,
                      userEmail:userEmail,
                      picture:picture
            
                        };

                axios.post('https://backend.conquerapps.com/api/check-user',  userData, {
                  headers: {    
                   'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'https://conquerapps.com/',
                  }
                } )
                  .then((response) => {
                    console.log(response.data.message.length);
                    if (response.data.message.length === 11) {
                      axios.post('https://backend.conquerapps.com/api/userinsert', userDataa, {
                        headers: {    
                         'Content-Type': 'application/json',
                          'Access-Control-Allow-Origin': 'https://conquerapps.com/',
                        }
                      } )
                        .then((response) => {
                          console.log(response.data.message);
                          //window.location.reload();
                        })
                        .catch((error) => {
                          console.error('There was an error inserting the data!', error.message);
                          console.error(error.response ? error.response.data : 'No response from server');
                        });
                    } else {
                      console.log('User exists in the database.');
                    }

                  })
                  .catch((error) => {
                    console.error('There was an error inserting the data!', error.message);
                    console.error(error.response ? error.response.data : 'No response from server');
                  });

                window.location.reload(); 
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />
          </GoogleOAuthProvider>
        )}



      </div>

      <div className={styles.tabsHeader}>
        {writeAcademicTabs.map((tab, index) => renderTab(tab, index))}
      </div>

      {renderTabContent()}

    </div>
  );

}

export default Home; 